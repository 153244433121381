<template>
    <v-container fluid class="px-8">
        <!-- 课程基本内容和状态 -->
        <v-card class="course-info mb-12" elevation="2">
            <v-img
                :src="getImgUrl4Type(course.cover, course.course_type)"
                height="125"
                width="200"
                max-width="200"
                class="grey darken-4 me-5"
            ></v-img>

            <div class="course-desc">
                <!-- <div class="desc-col two"> -->
                <div class="desc-col one">
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">
                            课程名称：
                        </div>
                        <div class="text">{{ course.name }}</div>
                    </div>
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">
                            课程说明：
                        </div>
                        <div class="text">{{ getText(course.desc, "暂无说明") }}</div>
                    </div>
                </div>
                <div class="desc-col one">
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">
                            难度级别：
                        </div>
                        <div class="rating">
                            <v-rating
                                :value="course.level"
                                color="yellow darken-3"
                                background-color="grey darken-1"
                                empty-icon="$ratingFull"
                                dense
                                readonly
                            ></v-rating>
                        </div>
                    </div>
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">
                            知识要点：
                        </div>
                        <div class="text">{{ course.points }}</div>
                    </div>
                    <!-- <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">状态：</div>
                        <div class="text">{{ course.status }}</div>
                    </div> -->
                </div>
            </div>
            <div class="operate">
                <v-btn
                    tile
                    dark
                    color="light-blue darken-1"
                    class="font-weight-bold text-body-1 mr-3"
                    @click.stop="viewStat(course.cid)"
                >
                    <template v-if="!isViewStat">
                        <v-icon left> mdi-align-vertical-bottom </v-icon>
                        练习统计
                    </template>
                    <template v-else>
                        <v-icon left> mdi-format-list-bulleted </v-icon>
                        课程内容
                    </template>
                </v-btn>
                <v-btn
                    tile
                    color="orange"
                    class="font-weight-bold text-body-1 mr-3"
                    @click.stop="gotoClassroom(course.cid)"
                    :dark="!isViewStat"
                    :disabled="isViewStat"
                >
                    <v-icon left> mdi-play </v-icon>
                    开始上课
                </v-btn>
            </div>
        </v-card>

        <!-- 当前课程小节列表 -->
        <v-data-table
            :headers="sectionHeader"
            :items="sections"
            item-key="id"
            class="elevation-2 mb-12"
            :disable-pagination="true"
            :hide-default-footer="true"
            v-if="!isViewStat"
        >
            <template v-slot:body="props">
                <draggable 
                    :list="props.items" 
                    tag="tbody"
                    @change="sectionListChange"
                >
                    <tr
                        v-for="(item, index) in props.items"
                        :key="index"
                        class="row-height"
                    >
                        <!-- 序号 -->
                        <td>{{ index + 1 }}</td>
                        <!-- 类型 -->
                        <td class="font-weight-medium">
                            <v-chip
                                :color="getSectionTypeColor(item.type)"
                                dark
                            >
                                {{ getTypeName(item.type) + getSubTypeName(item.sub_type) }}
                            </v-chip>
                        </td>
                        <!-- 小节名称 -->
                        <td class="td-line font-weight-medium">
                            <div class="active-name"
                                @click.stop="goto(item.section_id, item.sub_type)"
                            >
                                {{ item.name }}
                            </div>
                        </td>
                        <!-- 小节说明 -->
                        <td class="table-left">{{ item.desc }}</td>
                        <!-- <td>{{ item.status }}</td> -->
                        <!-- 是否可见 -->
                        <td>
                            <v-chip
                                label
                                small
                                class="font-weight-medium"
                                :color="item.is_show ? 'light-green' : 'light-blue darken-1'"
                                text-color="white"
                            >
                                {{ item.is_show ? '学生' : '仅老师' }}
                            </v-chip>
                        </td>
                        <!-- <td>
                            <v-tooltip top v-if="item.is_show">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs" 
                                        v-on="on"
                                        class="mx-2"
                                        outlined
                                        x-small
                                        icon
                                        height="32"
                                        width="32"
                                        :color="item.is_unlocked ? 'grey' : 'orange'"
                                        @click.stop="toggleIsUnLocked(item)"
                                    >
                                        <v-icon dark v-if="item.is_unlocked"> mdi-lock-open-variant-outline </v-icon>
                                        <v-icon dark v-else> mdi-lock </v-icon>
                                    </v-btn>
                                </template>
                                <span>锁定/解锁</span>
                            </v-tooltip>
                            <v-btn
                                v-else
                                class="mx-2"
                                outlined
                                x-small
                                icon
                                height="32"
                                width="32"
                                color="grey"
                                disabled
                            >
                                <v-icon dark> mdi-lock </v-icon>
                            </v-btn>
                        </td> -->
                        <!-- 操作 -->
                        <td>
                            <v-btn
                                tile
                                dark
                                color="orange"
                                class="font-weight-bold text-body-1"
                                style="margin: 0;"
                                max-height="36"
                                @click.stop="goto(item.section_id, item.sub_type)"
                            >
                                <v-icon left> mdi-eye </v-icon>
                                查看
                            </v-btn>
                        </td>
                    </tr>
                </draggable>
            </template>
        </v-data-table>
        <!-- 练习统计 -->
        <v-data-table
            :headers="statHeader"
            :items="stats"
            item-key="section_id"
            class="elevation-2 mb-12 row-pointer"
            :disable-pagination="true"
            :hide-default-footer="true"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            @click:row="expandRow"
            v-else
        >
            <template v-slot:item.index="{ item }">
                <div>{{ item.index + 1 }}</div>
            </template>
            <template v-slot:item.name="{ item }">
                <div class="font-weight-medium">
                    {{ item.name }}
                </div>
            </template>
            <template v-slot:item.type="{ item }">
                <v-chip :color="getSectionTypeColor(item.type)" dark class="font-weight-medium">
                    {{ getTypeName(item.type) + getSubTypeName(item.sub_type) }}
                </v-chip>
            </template>
            <template v-slot:expanded-item="{ headers }">
                <td :colspan="headers.length">
                    <div class="expendContent">
                        <v-chip
                            class="mr-2 mb-2 font-weight-medium"
                            style="cursor: pointer" 
                            v-for="stu in students"
                            v-bind:key="stu.id"
                            color="purple"
                            dark
                            @click.stop="viewPractice(stu.user_res_id, stu.sub_type)"
                        >
                            <v-icon left :size="18">
                                mdi-account
                            </v-icon>
                            {{ stu.name }}
                        </v-chip>
                    </div>
                </td>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import draggable from "vuedraggable";
import {
    getAdminCourse,
    getAdminSectionList4Class,
    getAdminPracticeStatList,
    getAdminPracticeUserList,
    updateAdminSectionListIndex,
    setAdminClassSectionUnlocked,
} from "@/api/admin";
import { 
    getTypeColor, 
    gotoSection, 
    getTypeName, 
    getSubTypeName, 
    getSectionTypeColor,
    getCourseInfo
} from '@/utils/common';
import { getText, getImgUrl, getTypeFirstLetter } from "@/utils/util";

export default {
    name: "Course",

    data() {
        return {
            classId: "",
            cid: "",
            course: {},

            defaultImg: {
                scratch: "35545445d836064a7fd834c58ce4",
                python: "e3db075709616499fe79760c35cd",
                robot: "8740291eaeafea6bf72e96571d63",
            },

            // 小节列表
            sections: [],
            sectionHeader: [
                {
                    text: "小节编号",
                    value: "index",
                    sortable: false,
                    align: "center",
                    width: 100,
                },
                {
                    text: "类型",
                    value: "type",
                    align: "center",
                    sortable: false,
                    width: 120,
                },
                { 
                    text: "小节名称",
                    value: "name", 
                    align: "center",
                    sortable: false,
                },
                {
                    text: "小节说明",
                    value: "desc", 
                    align: "left",
                    sortable: false,
                },
                {
                    text: "是否可见",
                    value: "is_show",
                    sortable: false,
                    align: "center",
                    width: 100,
                },
                // {
                //     text: "对学生开放",
                //     value: "is_unlocked",
                //     sortable: false,
                //     align: "center",
                //     width: 120,
                // },
                {
                    text: "操作",
                    value: "operate",
                    sortable: false,
                    align: "center",
                    width: 120,
                },
            ],

            // 练习统计
            stats: [],
            statHeader: [
                {
                    text: "小节编号",
                    value: "index",
                    sortable: false,
                    align: "center",
                    width: 100,
                },
                {
                    text: "类型",
                    value: "type",
                    align: "center",
                    sortable: false,
                },
                { 
                    text: "小节名称",
                    value: "name", 
                    align: "center",
                    sortable: false,
                },
                // { 
                //     text: "主要内容", 
                //     value: "desc", 
                //     sortable: false 
                // },
                { 
                    text: "练习人数", 
                    value: "user_count",
                    align: "center", 
                    sortable: false,
                },
                { 
                    text: "完成人数", 
                    value: "user_count",
                    align: "center", 
                    sortable: false,
                },
                // {
                //     text: "其他",
                //     value: "operate",
                //     sortable: false,
                //     align: "center",
                //     width: 160,
                // },
                { text: '', value: 'data-table-expand' },
            ],
            isViewStat: false,
            singleExpand: true,
            expanded: [],
            curExpandedLineId: "",

            students: [],

            fromPath: '',
        };
    },
    created() {
    },
    mounted() {
    },
    activated() {
        if (this.$route.query.id) {
            this.cid = this.$route.query.id;
        }
        if (this.$route.query.classId) {
            this.classId = this.$route.query.classId;
        }

        let isFromIde = false
        if (this.fromPath.substring(0, '/editor/'.length) == '/editor/') {
            isFromIde = true
        }
        if (this.fromPath.substring(0, '/exercise/'.length) == '/exercise/') {
            isFromIde = true
        }
        if (this.fromPath.substring(0, '/ide/'.length) == '/ide/') {
            isFromIde = true
        }
        if (!isFromIde) {
            this.reset()
            this.loadData()
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            // 通过 vm 访问组件实例,将值传入fromPath
            vm.fromPath = from.path;
        });
    },
    methods: {
        getText,
        getTypeColor,
        getTypeFirstLetter,
        gotoSection,
        getTypeName,
        getSubTypeName,
        getSectionTypeColor,
        getCourseInfo,
        getImgUrl4Type(imgId, type) {
            if (imgId == "" || imgId == null || imgId == undefined) {
                if (type == "SCRATCH") {
                    return getImgUrl(this.defaultImg.scratch)
                } else if (type == "PYTHON") {
                    return getImgUrl(this.defaultImg.python)
                } else if (type == "ROBOT") {
                    return getImgUrl(this.defaultImg.robot)
                }
                return ""
            }
            return getImgUrl(imgId)
        },
        reset() {
            this.isViewStat = false
            this.students = []
            this.stats = []
            this.curExpandedLineId = ""
        },
        loadData() {
            // 获取课程信息
            getAdminCourse(this.cid).then((response) => {
                // console.log(response.data);
                this.course = response.data;
            
            }).catch(function (err) {
                console.log(err);
            });

            // 获取课程小节列表
            getAdminSectionList4Class({ cid: this.cid, classId: this.classId }).then((response) => {
                // console.log(response.data);
                this.sections = response.data;
            
            }).catch(function (err) {
                console.log(err);
            });
        },
        loadStat(cid) {
            getAdminPracticeStatList({ cid: this.cid, classId: this.classId }).then((response) => {
                // console.log(response.data);
                this.stats = response.data;

            }).catch(function (err) {
                console.log(err);
            });
        },
    
        sectionListChange(e) {
            if (e.moved) {
                let oldIndex = e.moved.oldIndex
                let newIndex = e.moved.newIndex

                // 保存元素
                let tmp = this.sections[oldIndex]
                // 将元素从旧位置删除
                this.sections.splice(oldIndex, 1)
                // 将元素添加到新位置
                this.sections.splice(newIndex, 0, tmp)
            }

            this.updateAdminSectionListIndex()
        },
        async updateAdminSectionListIndex() {
            let sectionIds = []
            for (let i = 0; i < this.sections.length; i++) {
                sectionIds.push(this.sections[i].section_id)
            }

            await updateAdminSectionListIndex({
                id: this.cid,
                sectionIds: sectionIds
            })
        },
        goto(sid, subType) {
            this.gotoSection(this.course.cid, sid, subType, 'classroom', 'push')
        },
        viewPractice(sid, subType) {
            this.gotoSection(this.course.cid, sid, subType, 'exercise', 'push')
        },
        // 用于「开始上课」
        async gotoClassroom(cid) {
            // 加载上课信息状态
            await this.getCourseInfo(cid).then(res => {
                if (res.data && res.data.sections && res.data.sections.length > 0) {
                    let sid = res.data.sections[0].sid
                    // let sectionType = res.data.sections[0].type
                    let subType = res.data.sections[0].sub_type
                    this.gotoSection(this.course.cid, sid, subType, 'classroom', 'push')
                }
            })
        },
        viewStat(cid) {
            if (this.isViewStat) {
                this.isViewStat = false
            } else {
                this.isViewStat = true
                this.loadStat(cid)
            }
        },
        // 点击表格中的“行”，展开or收起
        expandRow(item) {
            // 如果当前有某行是展开状态，将其关闭
            if (this.expanded.length > 0) {
                this.expanded = []
            }

            // 如果多次点击当前行，将curExpandedLine重置
            if (this.curExpandedLineId == item.section_id) {
                this.curExpandedLineId = ""

            } else {

                this.expanded.push(item)

                getAdminPracticeUserList({ sid: item.section_id, classId: this.classId }).then((response) => {
                    this.students = response.data

                }).catch(function (err) {
                    console.log(err);
                });

                this.curExpandedLineId = item.section_id
            }
        },
        toggleIsUnLocked(item) {
            item.is_unlocked = !item.is_unlocked

            setAdminClassSectionUnlocked({
                classId: this.classId,
                cid: item.cid,
                sid: item.section_id,
                isUnlocked: item.is_unlocked
            })
        }
    },

    components: {
        draggable,
    },
};
</script>

<style lang="scss" scoped>
.alert-area {
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 0;
}
// 去掉v-card默认的上下的margin
.v-card {
    margin: 0;
}

.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.course-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .course-desc {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .desc-col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0 20px;

            &.one {
                flex: 1;
            }
            &.two {
                flex: 2;
            }
        }
        .desc-row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 35px;
            .desc-name {
                width: 70px;
                min-width: 70px;
            }
        }
        .text {
            text-align: left;
            font-weight: 300;
            font-size: 14px;
            white-space: nowrap;
        }
        .rating {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .operate {
        // margin: 0 20px;
    }
}
.table-left {
    text-align: left;
    white-space: nowrap;
}
.table-center {
    text-align: center;
}
.td-line {
    text-align: center;
    display: flex;
    justify-content: center;
    height: unset !important;
    .active-name {
        padding: 8px 25px;
        border-radius: 50px;
        // background-color: #efefef;
        background-color: #fff;
        &:hover {
            cursor: pointer;
            background-color: #fff;
            border-radius: 50px;
        }
    }
}
.expendContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
</style>

<!-- 让v-data-table行中的cursor显示pointer -->
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
