/**
 * 公共逻辑函数
 */

// 区分当前是web，还是admin
let curClient = 'admin'

const defaultHeaderConfig = {
    /**
     * 按钮部分
     */
    // Common
    logo: false,
    goBack: false,
    fullscreen: false,
    help: false,
    save: false,
    release: false,

    // Class
    fontPlus: false,
    fontMinus: false,

    // Scratch
    projName: false,
    data: false,
    code: false,
    copyRes: false,
    changeBg: false,

    // Python
    exercise: false,
    reset: false,
    createDemo: false,
    demoList: false,

    /**
     * 中间部分
     */
    title: true,

    /**
     * 右侧部分
     */
    sectionList: true,
    avatar: true,
}
export function setHeaderConfig(headerConfig) {
    let config = {}

    for (let name in defaultHeaderConfig) {
        config[name] = defaultHeaderConfig[name]
    }

    // new config
    for (let name in headerConfig) {
        config[name] = headerConfig[name]
    }

    return config
}

export function getTypeColor(type) {
    type = type.toUpperCase()
    switch (type) {
        case 'SCRATCH':
            return 'orange'
        case 'PYTHON':
            return 'blue'
        case 'C':
            return 'deep-orange'
        case 'ROBOT':
            return 'teal darken-1'
    }
}
export function getTypeName(type) {
    switch (type) {
        case "COURSE":
            return "练习";
        case "SLIDE":
            return "讲义";
        case "VIDEO":
            return "视频";
        default:
            return "";
    }
}
export function getSubTypeName(subType) {
    let sep = "："
    switch (subType) {
        case "SCRATCH":
            return sep + "Scratch";
        case "PYTHON":
            return sep + "Python";
        case "PPT":
            return sep + "PPT";
        case "MD":
            return sep + "Markdown";
        case "VIDEO":
            return sep + "视频";
        case "PDF":
            return sep + "PDF";
        default:
            return "";
    }
}
export function getSectionTypeColor(type) {
    switch (type) {
        case "COURSE":
            return "light-green";
        case "SLIDE":
            return "deep-purple accent-2";
        case "VIDEO":
            return "amber";
    }
}

export function getIdeType(path) {
    if (path == '/ide/scratch') {
        return 'SCRATCH'
    } else if (path == '/ide/python') {
        return 'PYTHON'
    } else if (path == '/ide/gython') {
        return 'G_PYTHON'
    }
    return ''
}

// 获取机构端学生状态
// 0: 未缴费
// 1: 已缴费，未激活
// 2: 已激活（已使用）
export function getStudentStatus(status) {
    if (status == 0) {
        return "未缴费"
    } else if (status == 1) {
        return "未激活"
    } else if (status == 2) {
        return "已激活"
    }
}

// 用于Header中的跳转
export function gotoSection(cid, id, subType, urlType, pushOrReplace) {
    let query = {}
    switch (urlType) {
        case 'classroom':
            query = { cid: cid, sid: id }
            break
        case 'editor':
            query = { cid: cid, sid: id }
            break
        case 'exercise':
            query = { pid: id }
            break
        case 'ide':
            query = { pid: id }
            break
    }

    if (pushOrReplace == 'push') {
        switch (subType) {
            case 'SCRATCH':
                this.$router.push({ path: '/' + urlType + '/scratch', query: query });
                break;
            case 'PYTHON':
                this.$router.push({ path: '/' + urlType + '/python', query: query });
                break;
            case 'PPT':
                this.$router.push({ path: '/' + urlType + '/slide-ppt', query: query });
                break;
            case 'MD':
                this.$router.push({ path: '/' + urlType + '/slide-md', query: query });
                break;
            case 'VIDEO':
                this.$router.push({ path: '/' + urlType + '/video', query: query });
                break;
            case 'PDF':
                this.$router.push({ path: '/' + urlType + '/pdf', query: query });
                break;
        }
    } else if (pushOrReplace == 'replace') {
        switch (subType) {
            case 'SCRATCH':
                this.$router.replace({ path: '/' + urlType + '/scratch', query: query });
                break;
            case 'PYTHON':
                this.$router.replace({ path: '/' + urlType + '/python', query: query });
                break;
            case 'PPT':
                this.$router.replace({ path: '/' + urlType + '/slide-ppt', query: query });
                break;
            case 'MD':
                this.$router.replace({ path: '/' + urlType + '/slide-md', query: query });
                break;
            case 'VIDEO':
                this.$router.replace({ path: '/' + urlType + '/video', query: query });
                break;
            case 'PDF':
                this.$router.replace({ path: '/' + urlType + '/pdf', query: query });
                break;
        }
    }
}

export function getCourseInfo(cid) {
    if (curClient == 'web') {
        return this.$store.dispatch('web/GetCourseInfo', cid)
    } else if (curClient == 'admin') {
        return this.$store.dispatch('web/GetAdminCourseInfo', cid)
    }
}
export function getSectionInfo(sid) {
    if (curClient == 'web') {
        return this.$store.dispatch('web/GetSectionInfo', sid)
    } else if (curClient == 'admin') {
        return this.$store.dispatch('web/GetAdminSectionInfo', sid)
    }
}
